@import "~@edx/brand/paragon/variables";
@import "~@edx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

a.course-card-title {
  color: $black;
}
.vertical-align {
  display: flex;
  align-items: center;
}
