@import "@edx/paragon/scss/core/core";

.explore-courses-btn {
  padding-top: 16px;
}

.panel-background {
  background: $light-200;
}

.recommendations-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.813rem;
  background: $light-200;
}
