@import "@edx/paragon/scss/core/core";

.card-link{
  display: block !important;
  margin: 0.5rem 0 0.5rem 0 !important;
}

.recommended-course-card {
    margin: 0.5rem 0 0.5rem 0 !important;

  .pgn__card-wrapper-image-cap {
    width: 7.188rem !important;
    max-width: 7.188rem !important;
    min-width: 7.188rem !important;
    height: 4.125rem !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 0.5rem;

    .pgn__card-image-cap {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
  .pgn__card-section {
    padding: 0;
  }
  margin-top: 0.313rem;
}

.text-info-500 {
  margin: 0 !important;
}
